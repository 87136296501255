
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import PageAuth from '@/components/layout/PageAuth.vue'

export default defineComponent({
  components: {
    PageAuth,
  },
  setup() {
    const route = useRoute()
    const country = computed(() => route.params.country || 'au')

    return {
      country,
    }
  },
})
